import { FunctionComponent } from 'react';
import * as React from 'react';
import classnames from 'classnames';

import styles from './main.scss';

type ModalBodyProps = {
    className?: string;
    children?: React.ReactNode;
    topSpacing?: 'small' | 'medium' | 'large';
};

export const ModalBody: FunctionComponent<ModalBodyProps> = props => {
    const { className, children, topSpacing = 'large' } = props;

    return (
        <div
            className={classnames(styles.modalBody, styles[topSpacing], className)}
            data-tn="modal-body"
        >
            {children}
        </div>
    );
};
