import { DEALER_DIR, ALL_VERTICALS_KEY } from './constants';

type UrlProps = {
    vertical?: string;
    verticals?: string;
    isDistinguished?: boolean;
    isTopSeller?: boolean;
    search?: string;
    company?: string;
};

export function getPath(
    {
        vertical: newVerticalSelection,
        verticals: oldVerticalSelection,
        isDistinguished,
        isTopSeller,
        search: newSearchString,
        company: oldSearchString,
    }: UrlProps,
    page?: number
): string {
    const segments: string[] = [];
    const queryParams = new URLSearchParams();

    if (page && page > 1) {
        queryParams.set('page', page + '');
    }

    if (isTopSeller) {
        const topSellerBase = `${DEALER_DIR}top/`;
        if (page && page > 1) {
            return `${topSellerBase}?${queryParams.toString()}`;
        }
        return topSellerBase;
    }

    const currentVertical = newVerticalSelection || oldVerticalSelection || ALL_VERTICALS_KEY;
    const currentSearch = newSearchString || oldSearchString;

    if (currentVertical !== ALL_VERTICALS_KEY) {
        segments.push(currentVertical);
    }

    if (currentSearch) {
        queryParams.set('search', encodeURIComponent(currentSearch));
    }

    if (isDistinguished && currentVertical !== ALL_VERTICALS_KEY) {
        queryParams.set('recognized', 'true');
    } else if (isDistinguished) {
        segments.push('recognized');
    }

    const basePath = `${DEALER_DIR}${segments.join('/')}${segments.length ? '/' : ''}`;
    if (queryParams.has('recognized') || queryParams.has('search') || queryParams.has('page')) {
        return `${basePath}?${queryParams.toString()}`;
    }
    return basePath;
}

export function getPaginationLink(props: UrlProps): (arg: number) => string {
    return (page: number): string => getPath(props, page);
}
