/**
 * @generated SignedSource<<46d807e3c718d3f18a552d567852473d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellersContainer_viewer$data = {
  readonly sellers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly serviceId: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"SellerTile_seller">;
      } | null;
    } | null> | null;
    readonly numberOfPages: number | null;
    readonly totalResults: number | null;
  } | null;
  readonly staticStorefrontBannerImages: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"SellerTile_staticStorefrontBannerImage">;
  }>;
  readonly " $fragmentType": "SellersContainer_viewer";
};
export type SellersContainer_viewer$key = {
  readonly " $data"?: SellersContainer_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SellersContainer_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "company"
    },
    {
      "kind": "RootArgument",
      "name": "isDistinguished"
    },
    {
      "kind": "RootArgument",
      "name": "isTopSeller"
    },
    {
      "kind": "RootArgument",
      "name": "page"
    },
    {
      "kind": "RootArgument",
      "name": "verticals"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SellersContainer_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StaticStorefrontBannerImageType",
      "kind": "LinkedField",
      "name": "staticStorefrontBannerImages",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SellerTile_staticStorefrontBannerImage"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "ascDesc",
          "value": "ASC"
        },
        {
          "kind": "Variable",
          "name": "company",
          "variableName": "company"
        },
        {
          "kind": "Literal",
          "name": "exactMatch",
          "value": false
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 60
        },
        {
          "kind": "Variable",
          "name": "isDistinguished",
          "variableName": "isDistinguished"
        },
        {
          "kind": "Literal",
          "name": "isMainStorefrontProfile",
          "value": true
        },
        {
          "kind": "Variable",
          "name": "isTopSeller",
          "variableName": "isTopSeller"
        },
        {
          "kind": "Literal",
          "name": "onlyIncludeLiveStorefrontProfile",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "COMPANY"
          ]
        },
        {
          "kind": "Variable",
          "name": "page",
          "variableName": "page"
        },
        {
          "kind": "Literal",
          "name": "status",
          "value": [
            "ACTIVE",
            "NO_PAY"
          ]
        },
        {
          "kind": "Variable",
          "name": "verticals",
          "variableName": "verticals"
        }
      ],
      "concreteType": "SellersConnection",
      "kind": "LinkedField",
      "name": "sellers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfPages",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalResults",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SellersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Seller",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serviceId",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SellerTile_seller"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "51cc831b55e3087ae44e6ffec55eb79c";

export default node;
