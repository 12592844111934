import { defineMessages } from 'dibs-react-intl';

export const DEALER_DIR = '/seller-directory/';

export const ALL_VERTICALS_KEY = 'all';

export const MOBILE_TYPE = 'mobile';

export const MAX_SELLER_LOGO_HEIGHT = '?height=115';

export const CATEGORY_MAP = defineMessages({
    [ALL_VERTICALS_KEY]: {
        id: 'abm.sellerDirectory.all',
        defaultMessage: 'All',
    },
    furniture: {
        id: 'abm.sellerDirectory.furniture',
        defaultMessage: 'Furniture',
    },
    art: {
        id: 'abm.sellerDirectory.art',
        defaultMessage: 'Art',
    },
    fashion: {
        id: 'abm.sellerDirectory.fashion',
        defaultMessage: 'Fashion',
    },
    jewelry: {
        id: 'abm.sellerDirectory.jewelry',
        defaultMessage: 'Jewelry & Watches',
    },
});
