import { FC, MouseEvent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';
import PlusIcon from 'dibs-icons/exports/legacy/Plus';
import { MOBILE_TYPE } from '../../helpers/constants';
import { getPaginationLink } from '../../helpers/urlHelpers';

// Components
import SellersHeader from '../SellersHeader/SellersHeader';
import SellerTile from '../SellerTile/SellerTile';
import Pagination from '../../../sharedComponents/Pagination/Pagination';

import styles from './SellersContainer.scss';

import { SellersContainer_viewer$data } from './__generated__/SellersContainer_viewer.graphql';

type Props = {
    viewer: SellersContainer_viewer$data;
    openMobileSidebar: () => void;
    company: string;
    devicetype: string;
    verticals: string;
    isTopSeller: boolean;
    isDistinguished: boolean;
    page: number;
    onPageChange: (pageNumber: number) => void;
};

const SellersContainer: FC<Props> = ({
    viewer,
    devicetype,
    company,
    verticals,
    isTopSeller,
    isDistinguished,
    openMobileSidebar,
    page,
    onPageChange,
}) => {
    const sellers = viewer?.sellers?.edges || [];
    const numberOfPages = viewer?.sellers?.numberOfPages || 1;
    const totalResults = viewer?.sellers?.totalResults || 0;
    const isMobile = devicetype === MOBILE_TYPE;

    return (
        <div
            className={`${styles.container} boxSizingWrapper containerFluid rowFlex colXs12 colXsLast colLg9`}
        >
            <SellersHeader company={company} totalResults={totalResults} />
            {isMobile && (
                <div className={`${styles.refineWrapper} colXs12 cf`} onClick={openMobileSidebar}>
                    <FormattedMessage
                        id="abm.sellerDirectory.results.refine"
                        defaultMessage="Refine"
                    />
                    <PlusIcon className={styles.plusIcon} />
                </div>
            )}

            {sellers
                .filter(filterNulls)
                .map(
                    (seller, key) =>
                        seller?.node && (
                            <SellerTile
                                key={`tile-${seller.node.serviceId || `page-${page}-item-${key}`}`}
                                seller={seller.node}
                                staticStorefrontBannerImages={viewer.staticStorefrontBannerImages}
                                sellerIndex={key}
                            />
                        )
                )}

            {numberOfPages > 1 && (
                <Pagination
                    numberOfPages={numberOfPages}
                    maxLength={isMobile ? 5 : 7}
                    getPaginationLink={getPaginationLink({
                        company,
                        verticals,
                        isTopSeller,
                        isDistinguished,
                    })}
                    page={page}
                    onPageChange={(e: MouseEvent, _page: number) => {
                        e.preventDefault();
                        onPageChange(_page);
                    }}
                />
            )}
        </div>
    );
};

export default createFragmentContainer(SellersContainer, {
    viewer: graphql`
        fragment SellersContainer_viewer on Viewer {
            staticStorefrontBannerImages {
                ...SellerTile_staticStorefrontBannerImage
            }
            sellers(
                status: [ACTIVE, NO_PAY]
                isMainStorefrontProfile: true
                orderBy: [COMPANY]
                ascDesc: ASC
                page: $page
                first: 60
                verticals: $verticals
                exactMatch: false
                company: $company
                isDistinguished: $isDistinguished
                isTopSeller: $isTopSeller
                onlyIncludeLiveStorefrontProfile: true
            ) {
                numberOfPages
                totalResults
                edges {
                    node {
                        serviceId
                        ...SellerTile_seller
                    }
                }
            }
        }
    `,
});
