import { graphql } from 'react-relay/legacy';

export default graphql`
    query sellerDirectoryRootQuery(
        $page: Int!
        $verticals: String
        $company: String
        $isDistinguished: Boolean!
        $isTopSeller: Boolean!
        $contentModulePreviewId: String
    ) {
        viewer {
            ...SellerDirectoryLayout_viewer
            sellers(
                status: [ACTIVE, NO_PAY]
                isMainStorefrontProfile: true
                orderBy: [COMPANY]
                ascDesc: ASC
                page: $page
                first: 60
                verticals: $verticals
                exactMatch: false
                company: $company
                isDistinguished: $isDistinguished
                isTopSeller: $isTopSeller
                onlyIncludeLiveStorefrontProfile: true
            ) {
                edges {
                    __typename
                }
            }
        }
    }
`;
