import { FC, MouseEvent, ReactNode, useEffect, useState, useCallback, useRef } from 'react';
import debounce from 'lodash.debounce';
import classNames from 'classnames';
import { colors, SassyColorNames } from 'dibs-sassy/exports/colors';
import { breakpoints } from 'dibs-sassy/exports/breakpoints';
import styles from './main.scss';

const TRIANGLE_SIZE = 8;

export type TooltipDirection = 'top' | 'bottom' | 'left' | 'right';

export type Alignment = 'center' | 'left' | 'right';

type ZIndex = 'bump' | 'low' | 'middle' | 'high';

export type TooltipProps = {
    isVisible: boolean;
    align?: Alignment;
    children?: ReactNode;
    closeOnOutsideClick?: boolean;
    dataTn?: string;
    direction?: TooltipDirection;
    showTriangle?: boolean;
    triangleFillColor?: SassyColorNames;
    onClose?: (e: Event) => void;
    paddingSize?: 'none' | 'small' | 'default';
    type?: 'large' | 'medium' | 'small' | 'coachmark' | 'x-large' | 'autoWidth';
    keepInViewport?: boolean;
    id?: string;
    zIndex?: ZIndex;
    role?: 'tooltip' | 'status';
};

type ContainerStyle = {
    top: string | number;
    bottom: string | number;
    left: string | number;
    right: string | number;
    width?: string | number;
    minWidth?: string | number;
};

type TriangleStyle = {
    triangleLeft: string | number;
    triangleTop: string | number;
};

type TooltipPositionReturnType = {
    triangleStyle: TriangleStyle;
    containerStyle: ContainerStyle;
};

const getTooltipStyles = (
    align: Alignment,
    elementRect: DOMRect | null,
    direction: TooltipDirection,
    rootRect: DOMRect | null,
    isMobile: boolean
): TooltipPositionReturnType => {
    const rootRectWidth = (rootRect && rootRect.width) || 0;
    const rootRectHeight = (rootRect && rootRect.height) || 0;
    const rootRectLeft = (rootRect && rootRect.left) || 0;
    const rootRectRight = (rootRect && rootRect.right) || 0;
    const elementRectWidth = (elementRect && elementRect.width) || 0;
    const elementRectHeight = (elementRect && elementRect.height) || 0;
    const halfRootWidth = rootRectWidth / 2;
    const halfRootHeight = rootRectHeight / 2;
    const negHalfElementWidth = (-1 * elementRectWidth) / 2;
    const negHalfElementHeight = (-1 * elementRectHeight) / 2;
    const left = isMobile
        ? -1 * (rootRectLeft - TRIANGLE_SIZE)
        : negHalfElementWidth + halfRootWidth;
    const top = negHalfElementHeight + halfRootHeight;
    const bottom = 'auto';
    const spacing = TRIANGLE_SIZE + 3;
    const contentVerticalPosition = rootRectHeight + spacing;
    const contentHorizontalPosition = rootRectWidth + spacing;
    const width = isMobile ? window.innerWidth - 2 * TRIANGLE_SIZE : undefined;

    const halfTriangleSize = TRIANGLE_SIZE / 2;

    const triangleVerticalPosition = {
        triangleLeft: halfRootWidth - TRIANGLE_SIZE - 1,
        triangleTop: rootRectHeight + halfTriangleSize - 1,
    };
    const triangleHorizontalPosition = {
        triangleLeft: rootRectWidth - 1,
        triangleTop: halfRootHeight - halfTriangleSize,
    };

    const stylingMap: Record<string, TooltipPositionReturnType> = {
        top: {
            containerStyle: {
                top: 'auto',
                bottom: contentVerticalPosition,
                left,
                right: 'auto',
                width,
            },
            triangleStyle: triangleVerticalPosition,
        },
        bottom: {
            containerStyle: {
                top: contentVerticalPosition,
                bottom,
                left,
                right: 'auto',
                width,
            },
            triangleStyle: triangleVerticalPosition,
        },
        left: {
            containerStyle: {
                top,
                bottom,
                left: 'auto',
                right: contentHorizontalPosition,
                width,
            },
            triangleStyle: triangleHorizontalPosition,
        },
        right: {
            containerStyle: {
                top,
                bottom,
                left: contentHorizontalPosition,
                right: 'auto',
                width,
            },
            triangleStyle: triangleHorizontalPosition,
        },
    };

    const directionStyles = stylingMap[direction];

    const alignAdjustableDirections = ['top', 'bottom'];

    if (alignAdjustableDirections.includes(direction)) {
        const isParentWider = elementRectWidth < rootRectWidth;
        if (align === 'left') {
            if (rootRectLeft - elementRectWidth >= 0) {
                directionStyles.containerStyle.left = 'auto';
                directionStyles.containerStyle.right = -18;
            }
            if (isParentWider) {
                directionStyles.triangleStyle.triangleLeft = 'calc(100% - 27px)';
            }
        } else if (align === 'right') {
            if (rootRectRight + elementRectWidth <= window.innerWidth) {
                directionStyles.containerStyle.right = 'auto';
                directionStyles.containerStyle.left = -18;
            }
            if (isParentWider) {
                directionStyles.triangleStyle.triangleLeft = 18;
            }
        } else if (align === 'center' && !isMobile) {
            const isOverLeftEdge = rootRectLeft - elementRectWidth / 2 < 0;
            if (isOverLeftEdge) {
                // bring it to the left edge + padding
                directionStyles.containerStyle.left = -rootRectLeft + 18;
                directionStyles.containerStyle.right = 'auto';
            }

            const isOverRightEdge =
                window.innerWidth - (rootRectLeft + rootRectWidth / 2 + elementRectWidth / 2) < 0;
            if (isOverRightEdge) {
                directionStyles.containerStyle.left = 'auto';
                // bring it to the right edge + padding
                directionStyles.containerStyle.right = -(window.innerWidth - rootRectRight) + 18;
            }
        }
    }

    return directionStyles;
};

type TriangleProps = TriangleStyle & {
    direction: TooltipDirection;
    zIndexClass?: string;
    triangleFillColor?: SassyColorNames;
};

const TooltipTriangle: FC<TriangleProps> = props => {
    const { triangleLeft, triangleTop, direction, zIndexClass, triangleFillColor } = props;
    const triangleStyle = {
        top: {
            left: triangleLeft,
            bottom: triangleTop,
        },
        bottom: {
            left: triangleLeft,
            top: triangleTop,
        },
        left: {
            top: triangleTop,
            right: triangleLeft,
        },
        right: {
            top: triangleTop,
            left: triangleLeft,
        },
    };
    const triangleWrapperStyles = {
        ...triangleStyle[direction],
        width: TRIANGLE_SIZE * 2,
        height: TRIANGLE_SIZE,
    };

    return (
        <div style={triangleWrapperStyles} className={classNames(styles.triangle, zIndexClass)}>
            <svg
                className={styles.triangleIcon}
                width={TRIANGLE_SIZE * 2}
                height={TRIANGLE_SIZE}
                viewBox="0 0 16 8"
                style={triangleFillColor ? { fill: colors[triangleFillColor] } : undefined}
            >
                <polygon points="8 0 16 8 0 8" />
                <polygon
                    className={styles.triangleStroke}
                    points="16 8 15.9105034 8 8.00745805 0.0865426222 0.0969546302 8 0 8 8.00745805 0"
                />
            </svg>
        </div>
    );
};

function getOutOfViewport(
    rootRect: DOMRect | null,
    elementRect: DOMRect | null
): Record<TooltipDirection, boolean> {
    const rootRectTop = (rootRect && rootRect.top) || 0;
    const rootRectBottom = (rootRect && rootRect.bottom) || 0;
    const rootRectLeft = (rootRect && rootRect.left) || 0;
    const rootRectRight = (rootRect && rootRect.right) || 0;
    const elementRectWidth = (elementRect && elementRect.width) || 0;
    const elementRectHeight = (elementRect && elementRect.height) || 0;
    return {
        top: rootRectTop - elementRectHeight < 0,
        bottom: rootRectBottom + elementRectHeight > window.innerHeight,
        left: rootRectLeft - elementRectWidth < 0,
        right: rootRectRight + elementRectWidth > window.innerWidth,
    };
}

function getDirectionInViewport(
    direction: TooltipDirection,
    keepInViewport: boolean,
    elementRect: DOMRect | null,
    rootRect: DOMRect | null
): TooltipDirection {
    if (!elementRect) {
        return direction;
    }
    if (!keepInViewport) {
        return direction;
    }
    const outOfViewport = getOutOfViewport(rootRect, elementRect);
    const OppositeDirectionMap = {
        top: 'bottom',
        bottom: 'top',
        left: 'right',
        right: 'left',
    };
    const opposite = OppositeDirectionMap[direction] as TooltipDirection;
    if (!outOfViewport[direction]) {
        return direction;
    } else if (!outOfViewport[opposite]) {
        return opposite;
    } else if (!outOfViewport.bottom) {
        return 'bottom';
    } else if (!outOfViewport.top) {
        return 'top';
    } else {
        return direction;
    }
}

function getClosestPositionedAncestor(el: Element | null): Element | null {
    if (!el) {
        return null;
    }
    const position = getComputedStyle(el).position;
    if (position === 'relative' || position === 'absolute') {
        return el;
    }
    return getClosestPositionedAncestor(el.parentElement);
}

export const Tooltip: FC<TooltipProps> = props => {
    const {
        align = 'center',
        children,
        closeOnOutsideClick = false,
        dataTn,
        keepInViewport = true,
        onClose = () => {},
        paddingSize,
        showTriangle = true,
        triangleFillColor,
        type = 'large',
        direction: directionProp = 'top',
        zIndex = 'bump',
        role = 'tooltip',
    } = props;
    const [direction, setDirection] = useState<TooltipDirection>(directionProp);
    const [containerStyle, setContainerStyle] = useState<ContainerStyle>({
        bottom: 'auto',
        right: 'auto',
        top: 0,
        left: 0,
        width: undefined,
        minWidth: 0,
    });
    const [trianglePosition, setTrianglePosition] = useState<TriangleStyle>({
        triangleTop: 0,
        triangleLeft: 0,
    });
    const [isVisible, setIsVisible] = useState(false);
    const [positionAdjusted, setPositionAdjusted] = useState(false);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const tooltipContentRef = useRef<HTMLDivElement>(null);

    const zIndexClass = classNames({
        [styles.bump]: zIndex === 'bump',
        [styles.low]: zIndex === 'low',
        [styles.middle]: zIndex === 'middle',
        [styles.high]: zIndex === 'high',
    });

    const containerClass = classNames(styles.container, zIndexClass, styles[direction], {
        [styles.isHidden]: !isVisible,
        [styles.isTransparent]: !positionAdjusted,
        [styles.medium]: type === 'medium',
        [styles.large]: type === 'large',
        [styles.small]: type === 'small',
        [styles.coachmark]: type === 'coachmark',
        [styles.xLarge]: type === 'x-large',
        [styles.autoWidth]: type === 'autoWidth',
    });

    function handleInsideClick(e: MouseEvent): void {
        e.stopPropagation();
    }

    const adjustPosition = useCallback(() => {
        const elementRect =
            tooltipContentRef.current && tooltipContentRef.current.getBoundingClientRect();
        const rootElement =
            tooltipRef.current && getClosestPositionedAncestor(tooltipRef.current.parentElement);
        let rootRect: DOMRect | null = null;
        if (rootElement) {
            rootRect = rootElement.getBoundingClientRect();
        }
        const isMobile = window.innerWidth <= breakpoints.mobile.max;
        const isTypeSmall = type === 'small';
        const isTypeAutoWidth = type === 'autoWidth';
        const adjustedAlignment = isMobile && !isTypeSmall ? 'center' : align;
        let adjustedDirection =
            isMobile && !isTypeSmall && direction !== 'bottom' ? 'top' : direction;
        adjustedDirection = getDirectionInViewport(
            adjustedDirection,
            isMobile && !isTypeSmall ? true : keepInViewport,
            elementRect,
            rootRect
        );
        const { containerStyle: newContainerStyle, triangleStyle: newTrianglePosition } =
            getTooltipStyles(
                adjustedAlignment,
                elementRect,
                adjustedDirection,
                rootRect,
                !isTypeSmall && !isTypeAutoWidth && isMobile
            );

        setDirection(adjustedDirection);
        setTrianglePosition(newTrianglePosition);
        setContainerStyle(newContainerStyle);
        setPositionAdjusted(true);
    }, [direction, align, keepInViewport, type]);

    useEffect(() => {
        setDirection(directionProp);
    }, [directionProp]);

    useEffect(() => {
        let timeoutRef: ReturnType<typeof setTimeout> | null = null;
        if (role === 'status' && props.isVisible) {
            // status toggletips need to re-render on visible to re-announce content to screen readers
            timeoutRef = setTimeout(() => setIsVisible(true), 50);
        } else {
            setIsVisible(props.isVisible);
        }
        return () => {
            if (timeoutRef) {
                clearTimeout(timeoutRef);
            }
        };
    }, [props.isVisible, role]);

    useEffect(() => {
        function checkOutsideClick(e: Event): void {
            if (isVisible && tooltipRef.current && !tooltipRef.current.contains(e.target as Node)) {
                setIsVisible(false);
                if (onClose) {
                    onClose(e);
                }
            }
        }

        const debouncedOutsideClick = debounce(checkOutsideClick, 200);
        // must add listener and remove on each render because the function will end up being different
        // each time this is rendered.
        // https://codedaily.io/tutorials/72/Creating-a-Reusable-Window-Event-Listener-Hook-with-useEffect-and-useCallback
        if (closeOnOutsideClick && isVisible) {
            document.addEventListener('click', debouncedOutsideClick, true);
            document.addEventListener('touchstart', debouncedOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', debouncedOutsideClick, true);
            document.removeEventListener('touchstart', debouncedOutsideClick, true);
        };
    }, [isVisible, onClose, closeOnOutsideClick, tooltipRef]);

    useEffect(() => {
        if (isVisible) {
            adjustPosition();
        }
    }, [adjustPosition, isVisible]);

    return (
        <div ref={tooltipRef}>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
            <div
                data-tn={dataTn || 'tooltip'}
                className={containerClass}
                style={containerStyle}
                onClick={handleInsideClick}
                ref={tooltipContentRef}
            >
                <div
                    role={role}
                    id={props.id}
                    className={classNames(styles.inner, {
                        [styles.smallPadding]: paddingSize === 'small',
                        [styles.noPadding]: paddingSize === 'none',
                    })}
                >
                    {((role === 'status' && isVisible) || role !== 'status') && children}
                </div>
            </div>
            {showTriangle && (
                <TooltipTriangle
                    {...trianglePosition}
                    triangleFillColor={triangleFillColor}
                    direction={direction}
                    zIndexClass={zIndexClass}
                />
            )}
        </div>
    );
};
