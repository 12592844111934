import { FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

import { ModalContainer } from 'dibs-elements/exports/ModalContainer';
import { ModalCloseButton } from 'dibs-elements/exports/ModalCloseButton';
import { ModalBody } from 'dibs-elements/exports/ModalBody';
import { BarHeader } from 'dibs-elements/exports/BarHeader';

import SideBar, { SidebarProps } from './Sidebar';

import styles from './SidebarMobile.scss';

type Props = SidebarProps & {
    isVisible?: boolean;
    closeMobileSidebar: () => void;
};

const SidebarMobile: FC<Props> = props => (
    <ModalContainer isOpen={props.isVisible || false} onClose={props.closeMobileSidebar}>
        <BarHeader
            title={
                <FormattedMessage
                    id="abm.sellerDirectory.sidebar.mobileTitle"
                    defaultMessage="Refine"
                />
            }
        />
        <ModalCloseButton onClick={props.closeMobileSidebar} />
        <ModalBody>
            <div className={styles.filters}>
                <FormattedMessage
                    id="abm.sellerDirectory.sidebar.filters"
                    defaultMessage="Filters"
                />
            </div>
            <SideBar {...props} />
        </ModalBody>
    </ModalContainer>
);

export default SidebarMobile;
