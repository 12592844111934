'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'dibs-elements/exports/Link';

// Tracking
import { trackEvent } from 'dibs-tracking';

class PaginationItem extends Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        const { nextPage, numberOfPages, onPageChange } = this.props;

        trackEvent(
            {
                action: 'pagination page number click',
                label: `${nextPage}/${numberOfPages}`,
                category: 'navigation',
            },
            event
        );

        if (onPageChange) {
            onPageChange(event, nextPage);
        }
    }

    render() {
        const { href, className, children, dataTn } = this.props;

        return (
            <Link href={href} className={className} onClick={this.onClick} dataTn={dataTn}>
                {children}
            </Link>
        );
    }
}

PaginationItem.propTypes = {
    children: PropTypes.node.isRequired,
    nextPage: PropTypes.number,
    numberOfPages: PropTypes.number,
    href: PropTypes.string,
    className: PropTypes.string,
    dataTn: PropTypes.string,
    onPageChange: PropTypes.func,
};

PaginationItem.defaultProps = {
    nextPage: 1,
    numberOfPages: 1,
    href: '#',
    className: '',
    dataTn: null,
};

export default PaginationItem;
