import { ChangeEvent, ReactElement, ReactNode } from 'react';
import classnames from 'classnames';

import styles from './main.scss';

export type RadioValue = string | number;

export type RadioProps<RV extends RadioValue> = {
    checked: boolean;
    children?: ReactNode;
    dataTn: string;
    disabled?: boolean;
    inputId?: string;
    maskForPrivacy?: boolean;
    label: ReactNode;
    labelClass?: string;
    labelContainerClass?: string;
    name: string;
    onChange: (value: RV, e: ChangeEvent<HTMLInputElement>) => void;
    radioClass?: string;
    value: RV;
    wrapperClass?: string;
    childrenClass?: string;
};

export function Radio<RV extends RadioValue>(props: RadioProps<RV>): ReactElement {
    const {
        checked,
        children,
        dataTn,
        disabled,
        inputId,
        maskForPrivacy,
        label,
        labelClass,
        labelContainerClass,
        name,
        onChange,
        radioClass,
        value,
        wrapperClass,
        childrenClass,
    } = props;

    const wrapperClasses = classnames(wrapperClass, {
        'fs-block': maskForPrivacy,
    });

    const labelContainerClasses = classnames(styles.labelContainer, labelContainerClass, {
        [styles.labelContainerDisabled]: disabled,
    });
    const buttonClasses = classnames(styles.radioButton, radioClass, {
        [styles.buttonDisabled]: disabled,
        [styles.buttonChecked]: checked,
    });
    const labelClasses = classnames(styles.label, labelClass, {
        [styles.labelContainerDisabled]: disabled,
    });
    const childClasses = classnames(styles.children, childrenClass, {
        [styles.childrenDisabled]: disabled,
    });
    const id = (inputId ? inputId : `${name}-${value.toString()}`).replace(/\s+/g, '-');

    return (
        <div className={wrapperClasses}>
            <input
                id={id}
                type="radio"
                name={name}
                value={value}
                checked={checked}
                disabled={disabled}
                onChange={e => onChange(value, e)}
                className={styles.hiddenInput}
                data-tn={`${dataTn}-input`}
            />
            <label
                htmlFor={id}
                className={labelContainerClasses}
                data-id={value}
                data-tn={`${dataTn}-label`}
            >
                <div className={buttonClasses} />
                {label && <div className={labelClasses}>{label}</div>}
            </label>
            {children && <div className={childClasses}>{children}</div>}
        </div>
    );
}
