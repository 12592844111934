import { FC } from 'react';
import { useIntl, defineMessages } from 'dibs-react-intl';

// Components
import SidebarSearchBox from '../SidebarSearchBox/SidebarSearchBox';
import FacetBox from '../FacetBox/FacetBox';
import SidebarCategory from '../SidebarCategory/SidebarCategory';
import SidebarFilter from '../SidebarFilter/SidebarFilter';

export type SidebarProps = {
    company?: string;
    verticals?: string;
    isTopSeller?: boolean;
    isDistinguished?: boolean;
    onChangeCompany: (company: string) => void;
    onChangeVerticals: (verticals: string) => void;
    onChangeTopSeller: (topSeller: boolean) => void;
    onChangeDistinguished: (distinguished: boolean) => void;
};

const messages = defineMessages({
    categorySection: {
        id: 'abm.SellerDirectory.categorySection',
        defaultMessage: 'Category',
    },
    displaySection: {
        id: 'abm.SellerDirectory.displaySection',
        defaultMessage: 'Display',
    },
});

const Sidebar: FC<SidebarProps> = props => {
    const intl = useIntl();
    return (
        <>
            <SidebarSearchBox {...props} />

            <FacetBox title={intl.formatMessage(messages.categorySection)}>
                <SidebarCategory {...props} />
            </FacetBox>

            <FacetBox title={intl.formatMessage(messages.displaySection)} last>
                <SidebarFilter {...props} />
            </FacetBox>
        </>
    );
};

export default Sidebar;
