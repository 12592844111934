import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './FacetBox.scss';

type Props = {
    title: string;
    children: ReactNode;
    last?: boolean;
};

const FacetBox: FC<Props> = ({ title, children, last = false }) => {
    const containerClass = classNames(styles.container, {
        [styles.separator]: !last,
    });

    return (
        <div className={containerClass}>
            <div className={styles.title}>{title}</div>
            {children}
        </div>
    );
};

export default FacetBox;
