type DeviceTypes = 'mobile' | 'tabletPortrait' | 'tabletLandscape' | 'desktop';

type Breakpoint = {
    max: number;
    min: number;
};

type Breakpoints = Record<DeviceTypes, Breakpoint>;

export const breakpoints: Breakpoints = {
    mobile: {
        // only here to satisy downstream typescript checks, do not use
        min: 0,
        max: 568,
    },
    tabletPortrait: {
        min: 569,
        max: 768,
    },
    tabletLandscape: {
        min: 769,
        max: 1024,
    },
    desktop: {
        min: 1025,
        // only here to satisy downstream typescript checks, do not use
        max: Infinity,
    },
};
