/**
 * @generated SignedSource<<5d9bc84bc4117dff68552ead21fe8d7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellerDirectoryContentModule_viewer$data = {
  readonly browseContentModule: {
    readonly top: {
      readonly description: string | null;
      readonly mainImage: string | null;
      readonly title: string | null;
      readonly type: string | null;
    } | null;
  } | null;
  readonly " $fragmentType": "SellerDirectoryContentModule_viewer";
};
export type SellerDirectoryContentModule_viewer$key = {
  readonly " $data"?: SellerDirectoryContentModule_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SellerDirectoryContentModule_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "contentModulePreviewId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SellerDirectoryContentModule_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "pageId",
          "variableName": "contentModulePreviewId"
        },
        {
          "kind": "Literal",
          "name": "pageName",
          "value": "browse:seller-directory"
        }
      ],
      "concreteType": "SingleBrowseContentModule",
      "kind": "LinkedField",
      "name": "browseContentModule",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BrowseContentModuleTop",
          "kind": "LinkedField",
          "name": "top",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mainImage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "75904263e748a1304563d6b28ba235bf";

export default node;
