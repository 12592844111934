import {
    useId,
    useState,
    useEffect,
    useRef,
    useMemo,
    ReactNode,
    FC,
    CSSProperties,
    TransitionEventHandler,
    MouseEventHandler,
    useCallback,
} from 'react';
import classnames from 'classnames';
import ArrowDown from 'dibs-icons/exports/legacy/ArrowDown';
import { colors, SassyColorNames } from 'dibs-sassy/exports/colors';
import { Link } from 'dibs-elements/exports/Link';
import styles from './main.scss';
import {
    hexToRGBA,
    getContentHeight,
    getSupportsLineClamp,
    useClampedHeight,
    useIsomorphicLayoutEffect,
} from './helpers';

const EXPANDED = 'expanded';
const PREP_COLLAPSE = 'prepCollapse';
const COLLAPSED = 'collapsed';
const COLLAPSING = 'collapsing';
const EXPANDING = 'expanding';

export type ExpandingAreaProps = {
    children?: ReactNode;
    dataTn?: string;
    expanded: boolean;
    expandingAreaClass?: string;
    childrenWrapperClass?: string;
    label?: ReactNode;
    labelClass?: string;
    maxHeight?: string | number;
    onClick?: MouseEventHandler;
    showArrow?: boolean;
    arrowClass?: string;
    showOverflow?: boolean;
    showGradient?: boolean;
    wrapperClass?: string;
    collapsedHeight?: string | number;
    setIsContentExpandable?: (arg0: boolean) => void;
    gradientColor?: SassyColorNames;
    lineClamp?: number;
    hideCollapsedContent?: boolean;
    unmountCollapsedContent?: boolean;
};

export const ExpandingArea: FC<ExpandingAreaProps> = ({
    children,
    dataTn,
    expanded = false,
    expandingAreaClass,
    childrenWrapperClass,
    label,
    labelClass,
    maxHeight,
    onClick = () => {},
    showArrow,
    arrowClass,
    showOverflow,
    showGradient = true,
    wrapperClass,
    collapsedHeight,
    setIsContentExpandable,
    gradientColor = 'sassyColorWhite',
    lineClamp,
    hideCollapsedContent,
    unmountCollapsedContent = false,
}) => {
    const supportsLineClamp = useMemo(() => getSupportsLineClamp(lineClamp), [lineClamp]);
    const [expandedState, setExpandedState] = useState(expanded ? EXPANDED : COLLAPSED);
    const [localContentExpandable, setLocalContentExpandable] = useState(!expanded);
    const heightWrapper = useRef<HTMLDivElement | null>(null);
    const htmlId = useId();

    collapsedHeight = useClampedHeight(
        supportsLineClamp,
        collapsedHeight,
        heightWrapper,
        styles.lineClamp,
        lineClamp
    );
    const realCollapsedHeight = localContentExpandable ? collapsedHeight : 'auto';
    const [contentHeight, setContentHeight] = useState(
        getContentHeight(heightWrapper, maxHeight, supportsLineClamp)
    );

    const isCollapsed = expandedState === COLLAPSED;
    // content must be unmounted only when `expandedState === COLLAPSED` to prevent
    // conditional rendering from interfering with open/close animations
    const mountContent = !(unmountCollapsedContent && isCollapsed);

    if (
        typeof maxHeight === 'string' &&
        typeof collapsedHeight !== 'undefined' &&
        parseInt(maxHeight) <=
            (typeof collapsedHeight === 'string' ? parseInt(collapsedHeight) : collapsedHeight)
    ) {
        // eslint-disable-next-line no-console
        console.warn(
            "dibs-elements | Expanding Area | max height is less than collapsed height. this doesn't make any sense. don't do this."
        );
    }
    if (!colors[gradientColor]) {
        throw new Error(
            'dibs-elements | Expanding Area | This is not a dibs-sassy color! Check spelling, use camel case, look at the style guide, come back when you are ready.'
        );
    }

    useEffect(() => {
        if (heightWrapper && heightWrapper.current) {
            let newCollapsedHeight = collapsedHeight || 0;
            if (typeof collapsedHeight === 'string') {
                newCollapsedHeight = parseInt(collapsedHeight);
            }
            const contentExpandable =
                heightWrapper.current.scrollHeight > (newCollapsedHeight as number);
            setLocalContentExpandable(contentExpandable);
            if (setIsContentExpandable) {
                setIsContentExpandable(contentExpandable);
            }
        }
    }, [heightWrapper, collapsedHeight, setIsContentExpandable]);

    //updates the contentHeight state on every transition when !unmountCollapsedContent && !contentHeight applies
    //this is necessary to have smooth expanding and collapsing animation
    const updatedContentHeightCb = useCallback(() => {
        if (!unmountCollapsedContent && !contentHeight) {
            const updatedContentHeight = getContentHeight(
                heightWrapper,
                maxHeight,
                supportsLineClamp
            );
            setContentHeight(updatedContentHeight);
        }
    }, [maxHeight, contentHeight, supportsLineClamp, unmountCollapsedContent]);

    // useLayoutEffect ensures full transition to collapsed/expanded
    useIsomorphicLayoutEffect(() => {
        if (!expanded && expandedState === EXPANDED) {
            setExpandedState(PREP_COLLAPSE);
        } else if (!expanded && expandedState === PREP_COLLAPSE) {
            updatedContentHeightCb();
            setExpandedState(COLLAPSING);
        } else if (expanded && expandedState === COLLAPSED) {
            updatedContentHeightCb();
            setExpandedState(EXPANDING);
        } else if (expanded && expandedState === COLLAPSING) {
            updatedContentHeightCb();
            setExpandedState(EXPANDING);
        } else if (!expanded && expandedState === EXPANDING) {
            setExpandedState(COLLAPSING);
            updatedContentHeightCb();
        }
    }, [expanded, expandedState]);

    useIsomorphicLayoutEffect(() => {
        // content height can be 0 in collapsed state
        // contentHeight is based on the heightWrapper ref, so it should be recalculated after updating the DOM
        // useLayoutEffect ensures browser will not repaint with wrong contentHeight
        const updatedContentHeight = getContentHeight(heightWrapper, maxHeight, supportsLineClamp);
        setContentHeight(updatedContentHeight);
    }, [isCollapsed, maxHeight, supportsLineClamp]);

    const handleTransitionEnd: TransitionEventHandler = e => {
        // only execute if transitioned property is height, or if e.propertyName is not
        // supported
        if (e.propertyName === 'height' || !e.propertyName) {
            if (expanded && expandedState !== EXPANDED) {
                setExpandedState(EXPANDED);
            } else if (!expanded && expandedState !== COLLAPSED) {
                setExpandedState(COLLAPSED);
            }
        }
    };

    useEffect(() => {
        // Special case when auto css height value equals the localMaxHeight and
        // onTransitionEnd does not trigger
        if (
            expandedState === EXPANDING &&
            heightWrapper?.current?.parentElement?.clientHeight === contentHeight &&
            heightWrapper?.current?.parentElement?.clientHeight !== 0 // exclude case if content is unmounted
        ) {
            setExpandedState(EXPANDED);
        }
    }, [expandedState, heightWrapper, contentHeight]);

    function getExpandingAreaHeight(): string | number | undefined {
        switch (expandedState) {
            case COLLAPSED:
                return collapsedHeight ? realCollapsedHeight : 0;
            case EXPANDING: {
                // if content is not lazyLoaded and unmountCollapsedContent is true, contentHeight === 0 is valid value
                // if expandingArea can be expanded on initial render and content is lazyLoaded, don't use unmountCollapsedContent === true
                // this sets contentHeight = 0 and lazyLoaded content does not show up
                if (unmountCollapsedContent) {
                    return contentHeight ?? 'inherit';
                } else {
                    return contentHeight || 'inherit';
                }
            }
            case EXPANDED:
                // maxHeight && contentHeight is true after initial render (bc it depends on an
                // HTMLElement ref). maxHeight alone is true if maxHeight prop is provided and
                // is initial render. otherwise set height to 'auto' to account for children
                // mutations.
                return (maxHeight && contentHeight) || maxHeight || 'auto';
            case PREP_COLLAPSE:
                return contentHeight || 'inherit';
            case COLLAPSING:
                return collapsedHeight ? realCollapsedHeight : 0;
        }
        return 0;
    }

    const stringLabelOrArrow = typeof label === 'string' || showArrow;
    const labelStyles = classnames(
        styles.labelWrapper,
        { [styles.stringLabel]: stringLabelOrArrow },
        labelClass
    );

    const expandingAreaClassName = classnames(
        styles.expandingArea,
        {
            [styles.showOverflow]: showOverflow && expandedState === EXPANDED,
            // keep .noScroll class until it's fully expanded to prevent scrollbar from appearing
            [styles.noScroll]: expandedState !== EXPANDED,
        },
        expandingAreaClass
    );

    const childrenWrapperClassName = classnames(
        {
            [styles.lineClamp]: expandedState === COLLAPSED,
            [styles.hideContent]: expandedState === COLLAPSED && hideCollapsedContent,
        },
        childrenWrapperClass
    );

    const gradientStyle = useMemo(() => {
        const color = colors[gradientColor];
        return {
            backgroundImage: `linear-gradient(${hexToRGBA(color, 0)} 0%, ${hexToRGBA(
                color,
                0.85
            )} 50%, ${hexToRGBA(color, 1)} 100%)`,
        };
    }, [gradientColor]);

    return (
        <div className={wrapperClass}>
            {label && (
                <Link
                    ariaControls={htmlId}
                    ariaExpanded={expandedState === EXPANDED}
                    underline="none"
                    className={labelStyles}
                    dataTn={dataTn}
                    onClick={onClick}
                >
                    <div className={styles.label}>{label}</div>
                    {stringLabelOrArrow && (
                        <ArrowDown
                            className={classnames(
                                styles.arrow,
                                { [styles.arrowUp]: expanded },
                                arrowClass
                            )}
                        />
                    )}
                </Link>
            )}
            {children && (
                <div
                    id={htmlId}
                    data-tn={`${dataTn ? dataTn + '-' : ''}expanding-area`}
                    data-state={expandedState}
                    className={expandingAreaClassName}
                    onTransitionEnd={handleTransitionEnd}
                    style={{ height: getExpandingAreaHeight() }}
                >
                    <div
                        onTransitionEnd={e => e.stopPropagation()}
                        ref={heightWrapper}
                        data-tn={`${dataTn ? dataTn + '-' : ''}expanding-area-children-wrapper`}
                        style={{ ['--expandingAreaLineClamp']: lineClamp } as CSSProperties}
                        className={childrenWrapperClassName}
                    >
                        {mountContent && children}
                    </div>
                    {(expandedState === COLLAPSED || expandedState === COLLAPSING) &&
                        localContentExpandable &&
                        showGradient && <div className={styles.gradient} style={gradientStyle} />}
                </div>
            )}
        </div>
    );
};
