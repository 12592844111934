import { FC, useState, useCallback } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';
import { Tooltip } from 'dibs-elements/exports/Tooltip';
import { SeoLink } from 'dibs-elements/exports/SeoLink';

import RecognizedIcon from 'dibs-icons/exports/legacy/RecognizedRibbon';
import DiamondIcon from 'dibs-icons/exports/legacy/Diamond';
import { MAX_SELLER_LOGO_HEIGHT } from '../../helpers/constants';

import styles from './SellerTile.scss';

// Tracking
import { trackEvent } from 'dibs-tracking';

import { SellerTile_seller$key } from './__generated__/SellerTile_seller.graphql';
import { SellerTile_staticStorefrontBannerImage$key } from './__generated__/SellerTile_staticStorefrontBannerImage.graphql';

type Props = {
    seller: SellerTile_seller$key | null | undefined;
    sellerIndex: number;
    staticStorefrontBannerImages: SellerTile_staticStorefrontBannerImage$key;
};

const SellerTile: FC<Props> = ({
    seller: sellerRef,
    sellerIndex,
    staticStorefrontBannerImages: staticStorefrontBannerImagesRef,
}) => {
    const [isRecognizedTooltipOpen, setRecognizedTooltipOpen] = useState<boolean>(false);
    const [isTopSellerTooltipOpen, setTopSellerTooltipOpen] = useState<boolean>(false);

    const staticStorefrontBannerImages = useFragment(
        graphql`
            fragment SellerTile_staticStorefrontBannerImage on StaticStorefrontBannerImageType
            @relay(plural: true) {
                path
            }
        `,
        staticStorefrontBannerImagesRef
    );

    const seller = useFragment(
        graphql`
            fragment SellerTile_seller on Seller {
                sellerProfile {
                    company
                }
                sellerPreferences {
                    urlLabel
                }
                shippingAddress {
                    displayCityStateCountry
                }
                storefrontProfiles {
                    logo {
                        path
                    }
                    banner {
                        path
                    }
                }
                dealerSince
                isDistinguished
                isDiamondSeller
                isTopSeller
            }
        `,
        sellerRef
    );

    const company = seller?.sellerProfile?.company;

    const onClick = useCallback(() => {
        trackEvent({
            action: 'dealer directory tile clicked',
            label: company,
            category: 'promo interaction',
        });
    }, [company]);

    if (!company || !seller) {
        return null;
    }

    const { isDistinguished, dealerSince } = seller;
    const link = seller.sellerPreferences?.urlLabel;
    const address = seller.shippingAddress?.displayCityStateCountry;

    const storefrontProfile = seller.storefrontProfiles?.[0];
    const logo = storefrontProfile?.logo?.path;
    const staticBannerIndex = sellerIndex % (staticStorefrontBannerImages.length - 1);
    const bannerImage =
        storefrontProfile?.banner?.path || staticStorefrontBannerImages[staticBannerIndex].path;
    const isTopSeller = seller.isTopSeller || seller.isDiamondSeller;

    return (
        <div className={`${styles.wrap} cf colXs6 colMd6 colLg6 colXl4`} data-tn="dealer-tile">
            <div className={`${styles.container}`}>
                <SeoLink
                    linkData={{
                        path: `/dealers/${link}/`,
                        isLinkable: false,
                    }}
                    className={styles.imageLink}
                    onClick={onClick}
                    dataTn="dealer-img-link"
                >
                    <div className={styles.imageWrap} data-tn="dealer-image-wrapper">
                        <img
                            className={`${styles.image} is-centered`}
                            src={`${bannerImage}${MAX_SELLER_LOGO_HEIGHT}`}
                            alt={company || ''}
                            data-tn="dealer-image"
                        />
                    </div>
                    <div className={styles.logoWrap} data-tn="dealer-logo-wrapper">
                        {logo && (
                            <img
                                className={`${styles.logo} is-centered`}
                                src={`${logo}${MAX_SELLER_LOGO_HEIGHT}`}
                                alt={company || ''}
                                data-tn="dealer-logo-image"
                            />
                        )}
                    </div>
                </SeoLink>

                <div className={styles.copy} data-tn="dealer-details">
                    <SeoLink
                        linkData={{
                            path: `/dealers/${link}/`,
                            isLinkable: true,
                        }}
                        className={styles.dealerLink}
                        onClick={onClick}
                        dataTn="dealer-company"
                    >
                        {company}
                    </SeoLink>
                    <div data-tn="dealer-since">
                        <FormattedMessage
                            id="abm.sellerDirectory.sellerTile.sellerSince"
                            defaultMessage="1stDibs seller since {dealerSince}"
                            values={{ dealerSince }}
                        />
                    </div>
                    <div data-tn="dealer-address">{address}</div>
                </div>
                <div className={styles.iconsWrapper} data-tn="dealer-icons">
                    {isTopSeller && (
                        <div
                            className={styles.icons}
                            onMouseOver={() => setTopSellerTooltipOpen(true)}
                            onFocus={() => setTopSellerTooltipOpen(true)}
                            onMouseOut={() => setTopSellerTooltipOpen(false)}
                            onBlur={() => setTopSellerTooltipOpen(false)}
                            data-tn="dealer-top-seller-icon"
                        >
                            <DiamondIcon />
                            <Tooltip
                                isVisible={isTopSellerTooltipOpen}
                                direction="top"
                                dataTn="tooltip"
                            >
                                <FormattedMessage
                                    id="abm.sellerDirectory.sellerTile.topSeller"
                                    defaultMessage="This distinction is for experienced sellers who continually surpass customer expectations."
                                />
                            </Tooltip>
                        </div>
                    )}
                    {isDistinguished && (
                        <div
                            className={styles.icons}
                            onMouseOver={() => setRecognizedTooltipOpen(true)}
                            onFocus={() => setRecognizedTooltipOpen(true)}
                            onMouseOut={() => setRecognizedTooltipOpen(false)}
                            onBlur={() => setRecognizedTooltipOpen(false)}
                            data-tn="dealer-recognized-icon"
                        >
                            <RecognizedIcon />
                            <Tooltip
                                isVisible={isRecognizedTooltipOpen}
                                direction="top"
                                dataTn="tooltip"
                            >
                                <FormattedMessage
                                    id="abm.sellerDirectory.sellerTile.recognized"
                                    defaultMessage="This designation is for sellers whose items consistently exemplify the best of 1stDibs."
                                />
                            </Tooltip>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SellerTile;
