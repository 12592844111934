import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type DiamondProps = {
    className?: string;
};

const Diamond: FunctionComponent<DiamondProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'diamond'}
            viewBox="0 0 250 250"
        >
            <path d="M197.5 14.7H53.4L-.3 81.3 126 235.5 249.7 81.3l-52.2-66.6zm-38.3 71l-34.3 129.4L89 85.7h70.2zm-67.9-8.9L123 27.3l33.6 49.4H91.3zm40-53.1h56.8l-23.7 48.6-33.1-48.6zM83.8 71.8L62.4 23.7h52.2L83.8 71.8zm-7.6 5H14.9l39.5-49 21.8 49zm3.5 8.9l33.2 119.5L15 85.7h64.7zm88.8 0h66.2L135.8 209l32.7-123.3zm3.7-8.9l24-49.2 38.6 49.2h-62.6z" />
        </svg>
    );
};
export default Diamond;
